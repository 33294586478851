import React, { useState, useEffect } from 'react'
import lodash from "lodash"
import formatPhoneNumber from '../../../../utils/formatPhoneNumber'
import validateEmail from '../../../../utils/validateEmail'
import formatLongNumber from '../../../../utils/formatLongNumber'
import validateNumberInput from '../../../../utils/validateNumberInput'
import validatePhone from '../../../../utils/validatePhone'
//TODO: This is not ideal to accomplish sending page events to analytics tools
import VEHICLE_DISPLAY_PAGE_EVENTS from '../../pages/vehicle-display-page/state/vehicleDisplayPageEvents'


// style
import './askaquestion.scss'

// icons
import CloseIcon from '../../../common/common-assets/icons/closeicon'

// redux and API
import {connect} from 'react-redux'
import MODAL_ACTIONS from '../state/modalActions'
import VEHICLE_DISPLAY_PAGE_ACTIONS from '../../pages/vehicle-display-page/state/vehicleDisplayPageActions'
import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../../api/aipDjapi'
import { vehicleInquiry } from '../../marketplaceAppUtils'

// components
import QuestionSentModal from './questionsent'
import LoadingSpinner from '../../../common/common-components/loadingSpinner'
import { toast } from 'react-toastify'

function AskQuestionModal(props) {
    const [tradeIn, setTradeIn] = useState(false)

    const [firstNameField, setFirstNameField] = useState("")
    const [lastNameField, setLastNameField] = useState("")
    const [emailField, setEmailField] = useState("")
    const [phoneNumberField, setPhoneNumberField] = useState("")
    const [messageField, setMessageField] = useState('')
    const [years, setYears] = useState(lodash.range(1960, new Date().getFullYear()+2))
    const [selectedYear, setSelectedYear] = useState('')
    const [yearValid, setYearValid] = useState()
    const [makes, setMakes] = useState([])
    const [selectedMake, setSelectedMake] = useState('')
    const [makeValid, setMakeValid] = useState()
    const [models, setModels] = useState([])
    const [selectedModel, setSelectedModel] = useState('')
    const [modelValid, setModelValid] = useState()
    const [mileageField, setMileageField] = useState('')
    const [mileageValid, setMileageValid] = useState()
    const [vinField, setVinField] = useState('')
    const [submitAttempted, setSubmitAttempted] = useState(false)

    const { closeModal } = props

    const [messageSent, setMessageSent] = useState(false)

    const [loading, setLoading] = useState(false)

    const tradeInValid = (tradeIn && selectedYear.length && selectedMake.length && selectedModel.length && mileageField.length ) || !tradeIn
    const firstNameValid = firstNameField.length
    const lastNameValid = lastNameField.length
    const emailValid = emailField.length && validateEmail(emailField)
    const phoneNumberValid = phoneNumberField.length && validatePhone(phoneNumberField)

    useEffect(() => {
        setUserProfile()
        getMakes()
        VEHICLE_DISPLAY_PAGE_EVENTS.sendVehicleEvent({event:'asc_cta_interaction',element_text:props.title, element_type:'body', element_subtype:'cta_button', event_action:'click', event_action_result:'open'})
    }, [])
    useEffect(()=>{
      if(selectedMake.length){
        getModels()
      }
    }, [selectedMake])
    const getMakes = () => {
        AIP_DJAPI(`${AIP_DJAPI_URLS.MAKE_LIST}`).then(response => {
          return response.json()
        }).then(res=>{
          if(res){
            setMakes(res)
          }
        })
    }

    const getModels = () => {
      AIP_DJAPI(`${AIP_DJAPI_URLS.MODEL_LIST}${selectedMake}/`).then(response =>{
      return response.json()
      }).then(res=>{
        if(res && res.models){
            setModels(res.models)
        }
      })
    }

    function allowSubmit(){
        if(phoneNumberField.length > 0) {
            return(tradeInValid && firstNameValid && lastNameValid && emailValid && phoneNumberValid)
        } else {
            return(tradeInValid && firstNameValid && lastNameValid && emailValid)
        }

    }

    function setUserProfile() {
        if (props.user) {
            setFirstNameField(props.user?.first_name)
            setLastNameField(props.user?.last_name)
            if (props.user?.phone_number) {
                setPhoneNumberField(props.user?.phone_number)
            }
            setEmailField(props.user?.email)
        }
    }

    function formSubmit() {
        setSubmitAttempted(true)
        if(allowSubmit()){
          let inquiry = {
            first_name: firstNameField,
            last_name: lastNameField,
            email: emailField,
          }
          if(phoneNumberField.length){
            inquiry.phone_number = phoneNumberField
          }
          if(messageField.length){
            inquiry.comment = messageField
          }
          if(tradeIn){
            inquiry = {
              ...inquiry,
              "trade_in_make": selectedMake,
              "trade_in_model": selectedModel,
              "trade_in_vin": vinField,
              "trade_in_mileage": mileageField,
              "trade_in_year": selectedYear,
            }
          }

          const stringifyInquiry = JSON.stringify(inquiry)

          setLoading(true)
          VEHICLE_DISPLAY_PAGE_EVENTS.sendVehicleEvent({event:'asc_form_submission',comm_type:'form',element_text:'Contact Dealer', department:'opportunity', form_name:props.title,form_type:'consumer_contact'})
          props.etsGenericActionSubmitEvent && VEHICLE_DISPLAY_PAGE_ACTIONS.sendGenericActionEtsRecording(props.etsGenericActionSubmitEvent)
          vehicleInquiry(stringifyInquiry, props.id).then(response => {
              if (response.status == 200) {
                  response.json().then(res => {
                      setMessageSent(true)
                  })
              } else {
                  toast("An error occurred, please try again later")
              }
              setLoading(false)

          })
        }
    }

    return (
        !messageSent ? (
            <div className='question-modal'>
                {loading && <LoadingSpinner loading />}

                <div className='modal-title'>{props.title}</div>
                <div className='close-modal' onClick={() => closeModal()}>
                    <CloseIcon />
                </div>

                <form>
                    <div className='form-row'>
                        <div className='form-input'>
                            {(!firstNameValid && submitAttempted) ? <div className='error'>Required</div>:null}
                            <input
                                type='text'
                                placeholder='First Name'
                                className='input'
                                onChange={e => setFirstNameField(e.target.value)}
                                value={firstNameField}
                            />
                        </div>

                        <div className='form-input'>
                            {(!lastNameValid && submitAttempted) ? <div className='error'>Required</div>:null}
                            <input
                                type='text'
                                placeholder='Last Name'
                                className='input'
                                onChange={e => setLastNameField(e.target.value)}
                                value={lastNameField}
                            />
                        </div>

                    </div>

                    <div className='form-row single'>
                        {(phoneNumberField.length && !phoneNumberValid && submitAttempted) ? <div className='error'>Please enter valid phone number</div>:null}
                        <input
                            type='text'
                            placeholder='Phone (Optional)'
                            className='input'
                            onChange={(e) =>{setPhoneNumberField(e.target.value)}}
                            value={formatPhoneNumber(phoneNumberField)}
                            maxLength={10}
                        />
                    </div>

                    <div className='form-row single'>
                        {(!emailValid && submitAttempted) ? <div className='error'>Please enter valid email address</div> : null}
                        <input
                            type='text'
                            placeholder='Email'
                            className='input'
                            onChange={e => setEmailField(e.target.value)}
                            value={emailField}
                        />
                    </div>

                    <div className='form-row single'>
                        <textarea
                            className='input'
                            placeholder='Message (Optional)'
                            value={messageField}
                            onChange={e => setMessageField(e.target.value)}
                        />
                    </div>

                    <div className='form-row checkbox'>
                        <input type='checkbox' onChange={() => setTradeIn(!tradeIn)} value={tradeIn} />
                        Do you have a trade-in?
                    </div>

                    {tradeIn &&
                        <div>
                            <div className='form-row'>
                                <div className='form-input'>
                                    {(tradeIn && submitAttempted && !selectedYear.length) ? <div className='error'>Required</div>:null }
                                    <select onChange={e => setSelectedYear(e.target.value)} className='dropdown' value={selectedYear}>
                                        <option value={null}>Year</option>
                                        {years.map(year => (
                                            <option value={year} key={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='form-input'>
                                    {(tradeIn && submitAttempted && !selectedMake.length) ?  <div className='error'>Required</div>:null}
                                    <select onChange={e => setSelectedMake(e.target.value)} className='dropdown' value={selectedMake} disabled={!selectedYear.length}>
                                        <option value={null}>Make</option>
                                        {makes.map(make => (
                                            <option value={make} key={make}>{make}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className='form-row single'>
                                <div className='form-input'>
                                    {(tradeIn && submitAttempted && !selectedModel.length) ? <div className='error'>Required</div>:null}
                                    <select onChange={e => setSelectedModel(e.target.value)} className='dropdown' value={selectedModel} disabled={!selectedMake.length}>
                                        <option value={null}>Model</option>
                                        {models.map(model => (
                                            <option value={model} key={model}>{model}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* <div className='form-input'>
                                    {errors?.color && <div className='error'>{errors.color.message}</div>}
                                    <select {...register('color', {required: 'Required'})} className='dropdown'>
                                        <option value={null} selected>Color</option>
                                        {colors.map(color => (
                                            <option value={color}>{color}</option>
                                        ))}
                                    </select>
                                </div> */}
                            </div>

                            <div className='form-row'>
                                <div className='form-input'>
                                    {(tradeIn && submitAttempted && !mileageField.length) ? <div className='error'>Please enter mileage</div>:null}
                                    <input
                                        type='text'
                                        onChange={e => setMileageField(e.target.value.replace(/,/g,''))}
                                        className='input'
                                        placeholder='Mileage'
                                        value={formatLongNumber(mileageField)}
                                        onKeyPress={e => validateNumberInput(e)}
                                        maxLength="10"
                                    />
                                </div>

                                <div className='form-input'>
                                    <input
                                        type='text'
                                        className='input'
                                        placeholder='VIN (Optional)'
                                        onChange={e => setVinField(e.target.value)}
                                        value={vinField}
                                        maxLength={17}
                                    />
                                </div>

                            </div>
                        </div>
                    }

                    <div className='form-row single button'>
                        <input value='Contact Dealer' className='primary-button full-width' onClick={formSubmit} type='button' />
                    </div>

                    <div className='message-bottom'>
                        <div className='message'>
                            By clicking here, you authorize iPacket.us and its sellers/partners to contact<br />
                            you by texts/calls which may include marketing and be by autodialer. Calls<br />
                            may be pre-recorded. You also agree to our Privacy Statement. Consent is<br />
                            not required to purchase goods/services.
                        </div>
                        <div className='message'>
                            We value your privacy. <a href="https://www.ipacket.us/dealer-center/privacy-policy" target="_blank"><div className='privacy'>iPacket Privacy Statement.</div></a>
                        </div>
                    </div>
                </form>
            </div>
        ) : (
            <QuestionSentModal dealerName={props.vehicle.store.full_name} />
        )
    )
}

const mapDispatchToProps={
    ...MODAL_ACTIONS.dispatchActions,
}

const mapStateToProps = state => ({
    user: state.userProfile.user,
    token: state.userProfile.token,
    vehicle: state.modal.modalList[0].props.vehicle
})

export default connect(mapStateToProps, mapDispatchToProps)(AskQuestionModal)
